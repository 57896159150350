<!--src/components/App.vue-->

<template>
  <div class="whois-lookup-container">
    <div class="search-and-results">
      <div class="search-section">
        <el-input
            ref="domainInput"
            v-model="domain"
            placeholder="Enter domain name (e.g. google.com)"
            :prefix-icon="Monitor"
            clearable
            @keyup.enter="queryWhois"
        >
          <template #append>
            <el-button type="primary" @click="queryWhois" :loading="loading">
              Search
            </el-button>
          </template>
        </el-input>
      </div>

      <div v-if="!result && !loading && !error" class="empty-state">
        <p>Supports most tlds and
          <el-tag size="small" effect="plain">.eth</el-tag>
          <el-tag size="small" effect="plain">.xyz</el-tag>
          <el-tag size="small" effect="plain">.ai</el-tag>
          <el-tag size="small" effect="plain">.si</el-tag>
        </p>
      </div>

      <div v-if="error" class="error-message">
        <el-alert
            :title="error"
            type="error"
            :closable="false"
            show-icon
        />
      </div>

      <div v-if="loading" class="loading-container">
        <el-skeleton :rows="6" animated />
      </div>

      <div
          v-if="result && result.status === 'available' && !loading"
          class="available-message"
      >
        <el-alert
            type="success"
            :closable="false"
            show-icon
        >
          <template #title>
            <a
                :href="`https://www.godaddy.com/domainsearch/find?domainToCheck=${domain}`"
                target="_blank"
                class="register-link"
            >
              Domain is available for registration!
            </a>
          </template>
          <template #description>
            This domain name is not registered and may be available for purchase.
          </template>
        </el-alert>
      </div>

      <div
          v-if="result && result.status === 'error' && !loading"
          class="error-message"
      >
        <el-alert
            :title="result.error || 'An error occurred while querying WHOIS information'"
            type="error"
            :closable="false"
            show-icon
        />
      </div>

      <component
          v-if="result && result.status === 'registered' && !loading"
          :is="currentTemplate"
          :whois-data="result"
          :domain="domain"
          class="result-section"
      />

      <DomainValuation
          v-if="queriedDomain"
          :domain="queriedDomain"
          class="domain-valuation-section"
      />
    </div>

    <div class="sidebars-container">
      <!-- Existing SideBar Component -->
      <SideBar
          :current-domain="queriedDomain"
          :is-loading="loading"
          :main-query-result="result"
      />

      <!-- New RelateLinks Component -->
      <RelateLinks
          :current-domain="queriedDomain"
          :is-loading="loading"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  defineAsyncComponent
} from 'vue';
import { Monitor } from '@element-plus/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import apiClient from '@/services/api';
import DefaultTemplate from '../templates/ComTemplate.vue';
import SideBar from './SideBar.vue';
import RelateLinks from './RelateLinks.vue';
import DomainValuation from './DomainValuation.vue';


const router = useRouter();
const route = useRoute();
const domain = ref('');
const result = ref(null);
const error = ref('');
const loading = ref(false);
const queriedDomain = ref('');
const domainInput = ref(null);

const loadTemplate = (tld) => {
  const formattedTld = tld.charAt(0).toUpperCase() + tld.slice(1);

  return defineAsyncComponent(() =>
      import(`../templates/${formattedTld}Template.vue`)
          .then((module) => module.default)
          .catch(() => {
            console.warn(`Template for .${tld} not found. Falling back to default template.`);
            return DefaultTemplate;
          })
  );
};

const currentTemplate = computed(() => {
  if (!result.value) return null;
  const domainToUse = result.value.domain || queriedDomain.value;
  const tld = domainToUse.split('.').pop().toLowerCase();
  return loadTemplate(tld);
});

const validateDomain = (domain) => {
  const domainRegex = /^([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
};

const generateMetaDescription = (whoisData, domain) => {
  if (!whoisData) return null;

  if (whoisData.status === 'available') {
    return `${domain} is available for registration. Check domain availability and registration details at WhoisJet.com.`;
  }

  if (whoisData.status === 'registered' && whoisData.raw_data) {
    let rawData;

    // 判断 raw_data 是字符串还是已经解析的对象
    try {
      rawData = typeof whoisData.raw_data === 'string'
          ? JSON.parse(whoisData.raw_data)
          : whoisData.raw_data;
    } catch (error) {
      console.error('Error parsing raw_data:', error);
      rawData = null;
    }

    if (!rawData) {
      return `${domain} is registered, but detailed WHOIS information could not be processed.`;
    }

    // 处理结构化 JSON 格式的情况
    if (rawData.entities || rawData.events || rawData.nameservers) {
      const registrar = rawData.entities?.find(entity => entity.roles?.includes('registrar'))?.vcardArray?.[1]?.find(vcard => vcard[0] === 'fn')?.[3] || 'Unknown registrar';
      const creationDate = rawData.events?.find(event => event.eventAction === 'registration')?.eventDate || 'Unknown date';
      const nameServers = rawData.nameservers?.map(ns => ns.ldhName).join(', ') || 'Unknown DNS';
      const registrant = rawData.entities?.find(entity => entity.roles?.includes('registrant'))?.vcardArray?.[1]?.find(vcard => vcard[0] === 'fn')?.[3] || 'Private Registration';

      // 格式化日期
      const formatDate = (dateStr) => {
        try {
          return new Date(dateStr).toISOString().split('T')[0];
        } catch (e) {
          return dateStr;
        }
      };

      return `${domain} was registered on ${formatDate(creationDate)} through ${registrar.trim()}. ` +
          `Registrant: ${registrant.trim()}. ` +
          `Name Servers: ${nameServers}. ` +
          `View complete WHOIS information at WhoisJet.com.`;
    }

    // 处理旧的 text 格式
    if (typeof rawData.text === 'string') {
      const registrar = rawData.text.match(/Registrar: ([^\n]+)/)?.[1] || 'Unknown registrar';
      const creationDate = rawData.text.match(/Creation Date: ([^\n]+)/)?.[1] || 'Unknown date';
      const nameServers = rawData.text.match(/Name Server: ([^\n]+)/g)?.map(ns => ns.split(': ')[1]).join(', ') || 'Unknown DNS';
      const registrant = rawData.text.match(/Registrant Organization: ([^\n]+)/)?.[1] ||
          rawData.text.match(/Registrant Name: ([^\n]+)/)?.[1] || 'Private Registration';

      // 格式化日期
      const formatDate = (dateStr) => {
        try {
          return new Date(dateStr).toISOString().split('T')[0];
        } catch (e) {
          return dateStr;
        }
      };

      return `${domain} was registered on ${formatDate(creationDate)} through ${registrar.trim()}. ` +
          `Registrant: ${registrant.trim()}. ` +
          `Name Servers: ${nameServers}. ` +
          `View complete WHOIS information at WhoisJet.com.`;
    }
  }

  return null;
};

const cleanDomain = (domain) => {
  return domain
      .trim()
      .toLowerCase()
      .replace(/^(https?:\/\/)?(www\.)?/, '')
      .replace(/\/.*$/, '')
      .replace(/[?#].*$/, '');
};

const fetchWhoisData = async (currentDomain) => {
  if (!currentDomain) return;

  loading.value = true;
  result.value = null;
  error.value = '';

  try {
    const response = await apiClient.get('/api/whois', {
      params: {
        domain: currentDomain
      }
    });

    if (response.data.status === 'error') {
      // Ensure error is a string
      if (typeof response.data.error === 'string') {
        error.value = response.data.error;
      } else if (response.data.error && response.data.error.message) {
        error.value = response.data.error.message;
      } else {
        error.value = 'An error occurred while querying WHOIS information';
      }
    } else {
      result.value = {
        ...response.data,
        domain: currentDomain
      };
    }
  } catch (err) {
    // Ensure error is a string
    if (err.response && err.response.data && typeof err.response.data.error === 'string') {
      error.value = err.response.data.error;
    } else if (err.message) {
      error.value = err.message;
    } else {
      error.value = 'Query failed, please try again later';
    }
    console.error('WHOIS query error:', err);
  } finally {
    loading.value = false;
  }
};

const queryWhois = async () => {
  if (!domain.value) {
    error.value = 'Please enter a domain name';
    return;
  }

  const cleanedDomain = cleanDomain(domain.value);

  if (!validateDomain(cleanedDomain)) {
    error.value = 'Please enter a valid domain format';
    return;
  }

  error.value = '';
  queriedDomain.value = cleanedDomain;

  try {
    // Navigate to /whois/:domain
    await router.push(`/whois/${queriedDomain.value}`);
  } catch (err) {
    console.error('Routing error:', err);
  }

  // Fetch WHOIS data explicitly to handle same-domain searches
  await fetchWhoisData(queriedDomain.value);
};

const clearState = () => {
  domain.value = '';
  queriedDomain.value = '';
  result.value = null;
  error.value = '';
  loading.value = false;
};

onMounted(() => {
  if (domainInput.value) {
    domainInput.value.focus();
  }

  if (route.params.domain) {
    domain.value = route.params.domain;
  }
});

// Watcher with immediate: true to handle initial load and route changes
watch(
    () => route.params.domain,
    async (newDomain) => {
      if (newDomain) {
        const cleanedDomain = cleanDomain(newDomain);
        domain.value = cleanedDomain;
        error.value = '';
        loading.value = true;
        result.value = null;

        queriedDomain.value = cleanedDomain;

        await fetchWhoisData(queriedDomain.value);
      } else {
        clearState();
      }
    },
    { immediate: true }
);

watch(result, (newResult) => {
  if (newResult) {
    const metaDesc = generateMetaDescription(newResult, queriedDomain.value);
    if (metaDesc) {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', metaDesc);
      }
    }
  }
});
</script>

<style scoped>
.whois-lookup-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.search-and-results {
  flex: 1.3;
}

.domain-valuation-section {
  margin-top: 20px;
}

.sidebars-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;
}

.search-section {
  margin-bottom: 10px;
}

/* Adjust the input wrapper */
:deep(.el-input__wrapper) {
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05) !important;
  height: 48px;
  line-height: 48px;
}

:deep(.el-input__inner) {
  font-size: 16px;
  height: 48px;
  line-height: 48px;
}

:deep(.el-input-group__append) {
  padding: 0;
  border: none;
  background: none;
}

:deep(.el-button) {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border-radius: 0 4px 4px 0;
  padding: 0 24px;
}

:deep(.el-input-group__append .el-button) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-input__prefix) {
  display: flex;
  align-items: center;
}

/* Loading and Result Sections */
.loading-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.result-section {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.result-section :deep(a) {
  color: inherit;
  text-decoration: none;
}

.error-message {
  margin-top: 20px;
}

.available-message {
  margin-top: 20px;
}

.register-link {
  color: inherit;
  text-decoration: none;
}

.empty-state {
  padding-left: 15px;
  color: #666;
}

.empty-state :deep(.el-tag) {
  margin: -2px 2px 0 2px;
  color: #666;
  border-color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .whois-lookup-container {
    flex-direction: column;
  }

  .domain-valuation-section {
    margin: 10px;
  }

  .sidebars-container {
    flex-direction: column;
    width: auto;
  }

  .search-section {
    margin: 0 10px 20px 10px;
  }

  .result-section {
    padding: 15px;
    border-radius: 0;
    margin: 10px 0;
    box-shadow: none;
  }

  .loading-container {
    padding: 15px;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
  }

  .error-message,
  .available-message {
    margin: 10px;
  }
}
</style>