<template>
  <div class="domain-valuation-card">
    <div class="card-header">
      <h3>Valuation</h3>
      <el-button
          v-if="valuationData"
          :loading="refreshing"
          @click="refreshValuation"
          type="default"
          size="default"
          class="refresh-btn"
      >
        Refresh
      </el-button>
    </div>

    <div v-if="loading" class="valuation-loading">
      <el-skeleton :rows="3" animated />
    </div>

    <div v-else-if="error" class="valuation-error">
      <el-alert
          :title="error"
          type="error"
          :closable="false"
          show-icon
      />
    </div>

    <div v-else-if="valuationData" class="valuation-result">
      <div class="response-text">
        {{ valuationData.response }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from 'vue';
import apiClient from '@/services/api';

const props = defineProps({
  domain: {
    type: String,
    required: true
  }
});

const loading = ref(false);
const refreshing = ref(false);
const error = ref('');
const valuationData = ref(null);
const userLanguage = ref('en');

const resetState = () => {
  valuationData.value = null;
  error.value = '';
  loading.value = false;
  refreshing.value = false;
};

const detectUserLanguage = () => {
  const browserLang = navigator.language.toLowerCase();
  userLanguage.value = browserLang.startsWith('zh') ? 'zh' : 'en';
};

const getValuation = async (isRefresh = false) => {
  if (!props.domain) return;

  if (isRefresh) {
    refreshing.value = true;
  } else {
    loading.value = true;
  }
  error.value = '';

  try {
    const response = await apiClient.get('/api/domain-valuation', {
      params: {
        domain: props.domain,
        language: userLanguage.value,
        refresh: isRefresh ? '1' : '0'
      }
    });

    if (response.data.error) {
      error.value = response.data.error;
    } else {
      valuationData.value = response.data;
    }
  } catch (err) {
    error.value = err.response?.data?.error || 'Failed to get domain valuation';
  } finally {
    loading.value = false;
    refreshing.value = false;
  }
};

const refreshValuation = () => getValuation(true);

watch(() => props.domain, (newDomain, oldDomain) => {
  if (newDomain !== oldDomain) {
    resetState();
    getValuation();
  }
});

onMounted(() => {
  detectUserLanguage();
  getValuation();
});
</script>

<style scoped>
.domain-valuation-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h3 {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
}

.refresh-btn {
  font-size: 14px;
  padding: 8px 15px;
}

.valuation-result {
  padding: 15px 0;
}

.response-text {
  color: #2c3e50;
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-break: break-word;
}

.valuation-loading,
.valuation-error {
  padding: 20px 0;
}
</style>